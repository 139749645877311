import Utils from "../../../utils";

export default class ErpOrderLineItemEntity {
    static getEntityData(elem: any, data: any) {
        return {
            id: elem.querySelector("#erp_order_line_item_id") ? (elem.querySelector("#erp_order_line_item_id") as HTMLInputElement).value : null,
            //label: (elem.querySelector("#erp_order_line_item_label") as HTMLInputElement).value,
            productId: (elem.querySelector("#erp_order_line_item_product_id") ? (elem.querySelector("#erp_order_line_item_product_id option:checked") as HTMLInputElement).value : (elem.querySelector("#new_erp_order_line_item_product_id option:checked") as HTMLInputElement).value).toLowerCase(),

            quantity: parseInt((elem.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).value),
            unitPrice: parseInt((elem.querySelector("#erp_order_line_item_unitPrice") as HTMLInputElement).value),
            serials: Utils.fixSerials((elem.querySelector("#erp_order_line_item_serials") as HTMLInputElement).value)
        }
    }
}